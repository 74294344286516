import React from "react"

import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import Container from "../../components/Container"

import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Grid from "../../components/Grid"
import ArticlePreview from "../../components/ArticlePreview"
import SafeAreaWrapper from "../../components/SafeAreaWrapper"

// { data: { backgroundImage } }
const ThankYouPage = ({ data: { recentBlog } }) => {
	return (
		<Layout translucent={false}>
			<Seo title="Thank you" description="" />

			<SafeAreaWrapper>
				<div className="mt-60 md:mt-80">
					<Grid className="py-spacer-xl flex flex-col">
						<div className="mb-10 col-span-12 grow-0 flex justify-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 73 75"
								className="w-[73px] h-[75px]">
								<g fill="none">
									<path
										fill="#FFF"
										d="M24.181 30.565 50.086 56.47 8.548 72.103 24.18 30.565ZM47.99 33.23l4.605.323-.43 6.138-4.604-.323.43-6.138ZM9.922 5.317l5.483 2.792-2.096 4.113-5.483-2.793 2.096-4.112Zm59.386 31.145a1.539 1.539 0 1 1-.001 3.078 1.539 1.539 0 0 1 0-3.078Zm-20-24.616a1.539 1.539 0 1 1-.001 3.078 1.539 1.539 0 0 1 0-3.078Z"
									/>
									<path
										fill="#50ADF1"
										d="M24.181 30.565 50.086 56.47 8.548 72.103 24.18 30.565ZM47.99 33.23l4.605.323-.43 6.138-4.604-.323.43-6.138ZM9.922 5.317l5.483 2.792-2.096 4.113-5.483-2.793 2.096-4.112Zm59.386 31.145a1.539 1.539 0 1 1-.001 3.078 1.539 1.539 0 0 1 0-3.078Zm-20-24.616a1.539 1.539 0 1 1-.001 3.078 1.539 1.539 0 0 1 0-3.078Z"
										opacity=".3"
									/>
									<path
										fill="#50ADF1"
										d="m21.878 18.968 10.78 10.781-.273-1.734 6.078-.963.722 4.559-4.028.637 14.05 14.05 7.595 7.593L.915 74.923H.82l21.058-55.955ZM9.351 60.985l-3.276 8.707 12.022-4.523c-2.937-1.094-5.852-2.489-8.746-4.184Zm4.238-11.256-3.137 8.331.276.165c3.698 2.183 7.418 3.843 11.163 4.98l.8.233 13.634-5.129c-7.753-.832-15.336-3.697-22.736-8.58Zm52.151 4.637 5.102 3.442-2.582 3.826-5.102-3.442 2.582-3.826ZM18.46 36.79l-3.757 9.983c9.319 6.299 18.848 9.173 28.63 8.656l.762-.045 7.154-2.692-3.566-3.565c-9.975-.774-19.723-4.898-29.223-12.337Zm53.33 9.202.505.243-1.367 2.755c-5.245-2.603-10.413-2.321-15.696.885l-.48.3-1.658-2.592c6.177-3.951 12.472-4.494 18.697-1.591ZM23.078 24.518l-3.482 9.245.431.343c7.93 6.28 15.966 10.1 24.12 11.486l-21.07-21.074Zm37.206-.509 5.782 2.106-1.579 4.337-5.781-2.106 1.578-4.337ZM58.405 4.068l3.07.172c-.529 9.365-4.703 16.786-12.444 22.142l-.546.37-1.705-2.561C53.89 19.46 57.728 13 58.363 4.694l.042-.626ZM38.092 19.043a1.539 1.539 0 1 1 0 3.078 1.539 1.539 0 0 1 0-3.078ZM29.077.266c4.31 6.943 4.408 14.063.298 21.12l-.292.492-2.628-1.603c3.707-6.073 3.8-11.97.285-17.929l-.278-.458L29.077.266Zm38.692 14.657a1.539 1.539 0 1 1 0 3.078 1.539 1.539 0 0 1 0-3.078ZM23.154 8.77a1.539 1.539 0 1 1-.001 3.078 1.539 1.539 0 0 1 0-3.078Zm18.44-5.303 4.183 1.95-2.602 5.58-4.183-1.951 2.602-5.579Z"
									/>
								</g>
							</svg>
						</div>

						<h1 className="my-10 display1 col-span-12 text-jetblack text-center grow-0">
							Congratulations
						</h1>

						<h3 className="my-10 font-headline text-xl leading-34 col-start-1 col-end-13 md:col-start-2 md:col-end-12 lg:col-start-3 lg:col-end-11 text-center grow-0">
							You have successfully taken the first step toward digital
							transformation. Our sales reps are preparing your personal product demo
							and will get back to you with a suggestion for an appointment within
							three working days.
						</h3>
						{/* <p className="my-20 font-normal leading-16 col-start-1 col-end-13 md:col-start-2 md:col-end-12 lg:col-start-4 lg:col-end-10 text-center grow-0">
							In the meantime...
						</p>
						<div className="mt-20 col-start-1 col-end-13 md:col-start-2 md:col-end-12 lg:col-start-4 lg:col-end-10 text-center grow-0">
							<Link to="/blog">
								<Button primary={true} label="Go to blog" />
							</Link>
						</div> */}
					</Grid>

					<div className="mb-spacer-xl">
						<Container>
							<h1 className="display1 mb-spacer-sm">Fresh content from our blog</h1>
						</Container>
						<Grid className="gap-y-30">
							{recentBlog.edges.map((edge, index) => {
								return (
									<div
										className={`group hover:cursor-pointer col-span-12 md:col-span-6 xl:col-span-4 flex flex-col ${
											index === 3 ? "xl:hidden" : ""
										}`}
										key={`blog-${index}`}>
										<Link to={`/blog/${edge.node.slug}`}>
											<span className="flex-none mr-12">
												<GatsbyImage
													className="mb-20 w-full h-[230px]"
													image={
														edge.node.teaser_image.imgixImage
															.gatsbyImageData
													}
													imgClassName="rounded-default overflow-hidden"
													alt={edge.node.title}
													title={edge.node.title}
												/>
											</span>
											<ArticlePreview
												date={edge.node.published_at}
												title={edge.node.title}
											/>
										</Link>
									</div>
								)
							})}
						</Grid>
						<Grid className="mt-spacer-xl gap-y-40">
							<div className="col-span-12 lg:col-span-6">
								<Link to="/faq">
									<div className="bg-pictonblue hover:shadow-xl duration-300 transition-color pt-60 pb-80 px-60 flex flex-col items-start rounded-medium h-full">
										<p className="captionSmall text-left text-white uppercase mb-10 text-md font-semibold">
											Frequently asked questions
										</p>
										<h2 className="display2 text-left text-white">
											FAQ - What we can do for your company
										</h2>
									</div>
								</Link>
							</div>
							<div className="col-span-12 lg:col-span-6">
								<Link to="/contact">
									<div className="bg-tealblue hover:shadow-xl duration-300 transition-color pt-60 pb-80 px-60 flex flex-col items-start rounded-medium h-full">
										<p className="captionSmall text-left text-white uppercase mb-10 text-md font-semibold">
											Say hello to us
										</p>
										<h2 className="display2 text-left text-white">
											Learn about our locations and contact our different
											offices
										</h2>
									</div>
								</Link>
							</div>
						</Grid>
					</div>
				</div>
			</SafeAreaWrapper>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		recentBlog: allMongodbBloghanseaticsoftArticles(
			sort: { order: DESC, fields: published_at }
			filter: { isFuture: { eq: false } }
			limit: 4
		) {
			edges {
				node {
					title
					published_at(formatString: "MMMM DD, YYYY")
					slug
					teaser_image {
						imgixImage {
							gatsbyImageData(width: 550)
						}
					}
				}
			}
		}
	}
`

export default ThankYouPage

// # backgroundImage: file(relativePath: { eq: "customer-support-operator.png" }) {
// # 	childImageSharp {
// # 		gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
// # 	}
// # }
